import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "heading", "container" ]

  containerTargetConnected(target) {
    const { title, pageTitle } = target.dataset
    
    if (title && title !== '') {
      this.headingTarget.innerText = title
    }

    if (pageTitle && pageTitle !== '') {
      document.title = pageTitle
    }
  }
}
